<template>
  <div id="form" class="fx-main container">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="0.97" :z-index="2147480000"></loading>
    <div v-if="user">
      <div class="company-data default-border    mg-top-2">
        <div class="form-header-title wd-100">
          <h2>Dados da Pessoa Jurídica</h2>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Razão social</label>
          <input type="text" class="default-border" id="company_name" v-model="user.company_name" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Nome fantasia</label>
          <input type="text" class="default-border" id="trading_name" v-model="user.trading_name" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="cnpj">CNPJ</label>
          <the-mask
            v-model="user.cnpj"
            name="cnpj"
            id="cnpj"
            mask="##.###.###/#####-##"
            type="text"
            :masked="true"
            class="default-border"
          ></the-mask>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Inscrição estadual</label>
          <the-mask
            v-model="user.ie"
            name="ie"
            id="ie"
            mask="###.###.###.###"
            type="text"
            :masked="true"
            class="default-border"
          ></the-mask>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Nome do responsável</label>
          <input type="text" class="default-border" id="name" v-model="user.name" />
        </div>
      </div>
      <div class="addresses default-border    mg-top-2">
        <div class="form-header-title wd-100">
          <h2>Endereços</h2>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="zipcode">CEP</label>
          <the-mask
            v-model="user.business_address.zipcode"
            name="zipcode"
            id="zipcode"
            mask="#####-###"
            type="text"
            :masked="true"
            class="default-border"
          ></the-mask>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Estado</label>
          <input type="text" class="default-border" id="name" v-model="user.business_address.state" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Cidade</label>
          <input type="text" class="default-border" id="name" v-model="user.business_address.city" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Endereço</label>
          <input
            type="text"
            class="default-border"
            id="name"
            v-model="user.business_address.address"
          />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Número</label>
          <input
            type="text"
            class="default-border"
            id="name"
            v-model="user.business_address.number"
          />
        </div>
      </div>
      <div class="contact-data default-border    mg-top-2">
        <div class="form-header-title wd-100">
          <h2>Dados de Contato</h2>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Celular</label>
          <input type="text" class="default-border" id="name" v-model="user.phone" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Email</label>
          <input type="text" class="default-border" id="name" v-model="user.email" />
        </div>
      </div>
      <div class="contact-data default-border mg-top-2">
        <div class="form-header-title wd-100">
          <h2>Dados Bancários</h2>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="name">Banco</label>
          <select id="bank_code" v-model="user.bank_code" class="default-border wd-100">
            <option
              v-for="code in bank_codes"
              :key="code.value"
              :value="code.value"
            >{{ code.label }} ({{ code.value }})</option>
          </select>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="bank_account_type">Tipo de Conta</label>
          <select
            id="bank_account_type"
            v-model="user.bank_account_type"
            class="default-border wd-100"
          >
            <option v-for="typ in bank_types" :key="typ.value" :value="typ.value">{{ typ.label }}</option>
          </select>
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="bank_agency">Agência</label>
          <input type="text" class="default-border" id="bank_agency" v-model="user.bank_agency" />
        </div>
        <div class="input-group fx-main mg-bot-2em">
          <label class="mg-right-2-em" for="bank_account">Conta</label>
          <input type="text" class="default-border" id="bank_account" v-model="user.bank_account" />
        </div>
      </div>
      <button class="mg-2 btn wd-100" @click="patchUser">Alterar</button>
    </div>
  </div>
</template>

<script>
import User from "@/services/user";
import util from "@/services/util";
import { TheMask } from "vue-the-mask";
import bank_codes from "@/services/bank_code";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


import Features from "@/services/features";

import boolean from "boolean";

export default {
  name: "SupplierForm",
  data() {
    return {
      user: null,
      isLoading: false,
      bank_codes: bank_codes,
      bank_types: [
        {
          value: "conta_corrente",
          label: "Conta Corrente"
        },
        {
          value: "conta_corrente_conjunta",
          label: "Conta Corrente Conjunta"
        },
        {
          value: "conta_poupanca_conjunta",
          label: "Conta Poupança Conjunsta"
        },
        {
          value: "conta_poupanca",
          label: "Conta Poupança"
        }
      ]
    };
  },
  mounted() {
    this.isLoading = true;
    User.self()
      .then(selfUser => {
        if(!selfUser) {
          return;
        }
        this.user = selfUser;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    patchUser() {
      this.isLoading = true;
      User.patch(this.user)
        .then(
          data => {
            Features.get().then(features => {
              if (features.approval && boolean(features.approval.required)) {
                this.$notify({
                  type: "success",
                  title: "Solicitação Enviada",
                  text:
                    "Solicitação de alteração cadastral enviada com sucesso!"
                });
              } else {
                this.$notify({
                  type: "success",
                  title: "Cadastro Alterado",
                  text: "Cadastro alterado com sucesso!"
                });
              }
            });
          },
          error => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Solicitar",
              text: util.stringifyAxiosError(error)
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  components: {
    "the-mask": TheMask,
    Loading
  }
};
</script>
